import React from 'react';
import classNames from 'classnames';

const Product = ({
  page: { frontmatter }
}) => {
  const [activeImage, setActiveImage] = React.useState(0);
  const unStar = (5 - frontmatter.rating) || 0;
  const fillStar = [...Array(frontmatter.rating).keys()] || [];
  const fillUnStar = ([...Array(unStar).keys()] || []);

  return (
    <section className="ss_product_single spacer_top spacer_bottom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="ss_product_img">
              <div className="thumbs_img">
                {/* Active Class Nmae `ss_active_bdr` */}
                {frontmatter.product_images &&
                frontmatter.product_images.map(({ image }, i) => (
                  <div
                    key={i} onClick={() => setActiveImage(i)}
                  >
                    <img className={classNames('img-fluid', activeImage === i && 'ss_active_bdr')} src={image} alt="ISS-YE" />
                  </div>
                ))}
              </div>
              <div className="product_zoom">
                {frontmatter.product_images &&
                  <img id="large_image" className="img-fluid" src={frontmatter.product_images[activeImage].image} alt="ISS-YE" />
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ss_product_cont" data-wow-delay="0.1s" data-wow-duration="1s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeIn'}}>
              <h1>{frontmatter.title}</h1>
              <h2>{frontmatter.price}</h2>
              <span className="ss_rating">
                {fillStar.map(r => (
                  <i
                    key={r}
                    className="fas fa-star"
                  />
                ))}
                {fillUnStar.map(r => (
                  <i
                    key={r}
                    className="far fa-star"
                  />
                ))}
              </span>
              <div className="ss_discount">
                {frontmatter.price_tags &&
                frontmatter.price_tags.map(({ tag }, i) => (
                  <p key={i}><i className="fas fa-tag" /> {tag}</p>
                ))}
              </div>
              {/* <div className="add_to_cart">
                <a href="http://kamleshyadav.com/html/solar-supplier/product-single.html#" className="ss_btn">Add to Cart</a>
                <a href="http://kamleshyadav.com/html/solar-supplier/product-single.html#" className="ss_btn">Add to Wishlist</a>
              </div> */}
              <div className="ss_specifi">
                <h2>Specification</h2>
                {frontmatter.specification &&
                frontmatter.specification.map((spe, i) => (
                  <p key={i}><span>{spe.name}</span> <span>{spe.value}</span></p>
                ))}
              </div>
            </div>
          </div>
        </div>	
        <div className="ss_product_disc spacer_top">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {/* <li className="nav-item">
              <a className="nav-link active" id="discription-tab" data-toggle="tab" href="http://kamleshyadav.com/html/solar-supplier/product-single.html#discription" role="tab" aria-controls="discription" aria-selected="false">Discription</a>
            </li> */}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade active show" id="discription" role="tabpanel" aria-labelledby="discription-tab">
              <h2>Discription</h2>
              <p>{frontmatter.description}</p>
            </div>
          </div>
        </div>
      </div>	
    </section>
  )
}

export default Product;
