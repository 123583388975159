import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Header from 'components/Header';
import Link from 'components/Link';
import Product from 'components/Product/index';
import i18nx from 'components/locale/i18nx';


const SingleProduct = ({
  data: {
    markdownRemark: { frontmatter },
  },
  location,
}) => {
  const lang = location && location.pathname.split('/')[1];

  return (
    <Layout>
      <SEO
        lang={lang}
        title={frontmatter.title}
        description={frontmatter.short_description}
        image={frontmatter.wrapper}
      />
      <Header lang={lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{frontmatter.title}</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / products</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <Product page={{ frontmatter }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Product($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        wrapper
        lang
        templateType
        description
        short_description
        price
        rating
        product_images {
          image
        }
        specification {
          name
          value
        }
        price_tags {
          tag
        }
      }
    }
  }
`
export default SingleProduct;
